var rosePineMoon = Object.freeze({
  "colors": {
    "activityBar.activeBorder": "#e0def4",
    "activityBar.background": "#232136",
    "activityBar.dropBorder": "#393552",
    "activityBar.foreground": "#e0def4",
    "activityBar.inactiveForeground": "#908caa",
    "activityBarBadge.background": "#ea9a97",
    "activityBarBadge.foreground": "#232136",
    "badge.background": "#ea9a97",
    "badge.foreground": "#232136",
    "banner.background": "#2a273f",
    "banner.foreground": "#e0def4",
    "banner.iconForeground": "#908caa",
    "breadcrumb.activeSelectionForeground": "#ea9a97",
    "breadcrumb.background": "#232136",
    "breadcrumb.focusForeground": "#908caa",
    "breadcrumb.foreground": "#6e6a86",
    "breadcrumbPicker.background": "#2a273f",
    "button.background": "#ea9a97",
    "button.foreground": "#232136",
    "button.hoverBackground": "#ea9a97e6",
    "button.secondaryBackground": "#2a273f",
    "button.secondaryForeground": "#e0def4",
    "button.secondaryHoverBackground": "#393552",
    "charts.blue": "#9ccfd8",
    "charts.foreground": "#e0def4",
    "charts.green": "#3e8fb0",
    "charts.lines": "#908caa",
    "charts.orange": "#ea9a97",
    "charts.purple": "#c4a7e7",
    "charts.red": "#eb6f92",
    "charts.yellow": "#f6c177",
    "checkbox.background": "#2a273f",
    "checkbox.border": "#817c9c26",
    "checkbox.foreground": "#e0def4",
    "debugExceptionWidget.background": "#2a273f",
    "debugExceptionWidget.border": "#817c9c26",
    "debugIcon.breakpointCurrentStackframeForeground": "#908caa",
    "debugIcon.breakpointDisabledForeground": "#908caa",
    "debugIcon.breakpointForeground": "#908caa",
    "debugIcon.breakpointStackframeForeground": "#908caa",
    "debugIcon.breakpointUnverifiedForeground": "#908caa",
    "debugIcon.continueForeground": "#908caa",
    "debugIcon.disconnectForeground": "#908caa",
    "debugIcon.pauseForeground": "#908caa",
    "debugIcon.restartForeground": "#908caa",
    "debugIcon.startForeground": "#908caa",
    "debugIcon.stepBackForeground": "#908caa",
    "debugIcon.stepIntoForeground": "#908caa",
    "debugIcon.stepOutForeground": "#908caa",
    "debugIcon.stepOverForeground": "#908caa",
    "debugIcon.stopForeground": "#eb6f92",
    "debugToolBar.background": "#2a273f",
    "debugToolBar.border": "#393552",
    "descriptionForeground": "#908caa",
    "diffEditor.border": "#393552",
    "diffEditor.diagonalFill": "#817c9c4d",
    "diffEditor.insertedLineBackground": "#9ccfd826",
    "diffEditor.insertedTextBackground": "#9ccfd826",
    "diffEditor.removedLineBackground": "#eb6f9226",
    "diffEditor.removedTextBackground": "#eb6f9226",
    "diffEditorOverview.insertedForeground": "#9ccfd880",
    "diffEditorOverview.removedForeground": "#eb6f9280",
    "dropdown.background": "#2a273f",
    "dropdown.border": "#817c9c26",
    "dropdown.foreground": "#e0def4",
    "dropdown.listBackground": "#2a273f",
    "editor.background": "#232136",
    "editor.findMatchBackground": "#817c9c4d",
    "editor.findMatchHighlightBackground": "#817c9c4d",
    "editor.findRangeHighlightBackground": "#817c9c4d",
    "editor.findRangeHighlightBorder": "#0000",
    "editor.focusedStackFrameHighlightBackground": "#817c9c26",
    "editor.foldBackground": "#2a273f",
    "editor.foreground": "#e0def4",
    "editor.hoverHighlightBackground": "#0000",
    "editor.inactiveSelectionBackground": "#817c9c14",
    "editor.inlineValuesBackground": "#0000",
    "editor.inlineValuesForeground": "#908caa",
    "editor.lineHighlightBackground": "#817c9c14",
    "editor.lineHighlightBorder": "#0000",
    "editor.linkedEditingBackground": "#2a273f",
    "editor.rangeHighlightBackground": "#817c9c14",
    "editor.selectionBackground": "#817c9c26",
    "editor.selectionForeground": "#e0def4",
    "editor.selectionHighlightBackground": "#817c9c26",
    "editor.selectionHighlightBorder": "#232136",
    "editor.snippetFinalTabstopHighlightBackground": "#817c9c26",
    "editor.snippetFinalTabstopHighlightBorder": "#2a273f",
    "editor.snippetTabstopHighlightBackground": "#817c9c26",
    "editor.snippetTabstopHighlightBorder": "#2a273f",
    "editor.stackFrameHighlightBackground": "#817c9c26",
    "editor.symbolHighlightBackground": "#817c9c26",
    "editor.symbolHighlightBorder": "#0000",
    "editor.wordHighlightBackground": "#817c9c26",
    "editor.wordHighlightBorder": "#0000",
    "editor.wordHighlightStrongBackground": "#817c9c26",
    "editor.wordHighlightStrongBorder": "#817c9c26",
    "editorBracketHighlight.foreground1": "#eb6f9280",
    "editorBracketHighlight.foreground2": "#3e8fb080",
    "editorBracketHighlight.foreground3": "#f6c17780",
    "editorBracketHighlight.foreground4": "#9ccfd880",
    "editorBracketHighlight.foreground5": "#ea9a9780",
    "editorBracketHighlight.foreground6": "#c4a7e780",
    "editorBracketMatch.background": "#0000",
    "editorBracketMatch.border": "#908caa",
    "editorBracketPairGuide.activeBackground1": "#3e8fb0",
    "editorBracketPairGuide.activeBackground2": "#ea9a97",
    "editorBracketPairGuide.activeBackground3": "#c4a7e7",
    "editorBracketPairGuide.activeBackground4": "#9ccfd8",
    "editorBracketPairGuide.activeBackground5": "#f6c177",
    "editorBracketPairGuide.activeBackground6": "#eb6f92",
    "editorBracketPairGuide.background1": "#3e8fb080",
    "editorBracketPairGuide.background2": "#ea9a9780",
    "editorBracketPairGuide.background3": "#c4a7e780",
    "editorBracketPairGuide.background4": "#9ccfd880",
    "editorBracketPairGuide.background5": "#f6c17780",
    "editorBracketPairGuide.background6": "#eb6f9280",
    "editorCodeLens.foreground": "#ea9a97",
    "editorCursor.background": "#e0def4",
    "editorCursor.foreground": "#6e6a86",
    "editorError.border": "#0000",
    "editorError.foreground": "#eb6f92",
    "editorGhostText.foreground": "#908caa",
    "editorGroup.border": "#0000",
    "editorGroup.dropBackground": "#2a273f",
    "editorGroup.emptyBackground": "#0000",
    "editorGroup.focusedEmptyBorder": "#0000",
    "editorGroupHeader.noTabsBackground": "#0000",
    "editorGroupHeader.tabsBackground": "#0000",
    "editorGroupHeader.tabsBorder": "#0000",
    "editorGutter.addedBackground": "#9ccfd8",
    "editorGutter.background": "#232136",
    "editorGutter.commentRangeForeground": "#908caa",
    "editorGutter.deletedBackground": "#eb6f92",
    "editorGutter.foldingControlForeground": "#c4a7e7",
    "editorGutter.modifiedBackground": "#ea9a97",
    "editorHint.border": "#0000",
    "editorHint.foreground": "#908caa",
    "editorHoverWidget.background": "#2a273f",
    "editorHoverWidget.border": "#6e6a8680",
    "editorHoverWidget.foreground": "#908caa",
    "editorHoverWidget.highlightForeground": "#e0def4",
    "editorHoverWidget.statusBarBackground": "#0000",
    "editorIndentGuide.activeBackground": "#6e6a86",
    "editorIndentGuide.background": "#817c9c4d",
    "editorInfo.border": "#393552",
    "editorInfo.foreground": "#9ccfd8",
    "editorInlayHint.background": "#393552",
    "editorInlayHint.foreground": "#908caa",
    "editorInlayHint.parameterBackground": "#393552",
    "editorInlayHint.parameterForeground": "#c4a7e7",
    "editorInlayHint.typeBackground": "#393552",
    "editorInlayHint.typeForeground": "#9ccfd8",
    "editorLightBulb.foreground": "#3e8fb0",
    "editorLightBulbAutoFix.foreground": "#ea9a97",
    "editorLineNumber.activeForeground": "#e0def4",
    "editorLineNumber.foreground": "#908caa",
    "editorLink.activeForeground": "#ea9a97",
    "editorMarkerNavigation.background": "#2a273f",
    "editorMarkerNavigationError.background": "#2a273f",
    "editorMarkerNavigationInfo.background": "#2a273f",
    "editorMarkerNavigationWarning.background": "#2a273f",
    "editorOverviewRuler.addedForeground": "#9ccfd880",
    "editorOverviewRuler.background": "#232136",
    "editorOverviewRuler.border": "#817c9c4d",
    "editorOverviewRuler.bracketMatchForeground": "#908caa",
    "editorOverviewRuler.commonContentForeground": "#817c9c14",
    "editorOverviewRuler.currentContentForeground": "#817c9c26",
    "editorOverviewRuler.deletedForeground": "#eb6f9280",
    "editorOverviewRuler.errorForeground": "#eb6f9280",
    "editorOverviewRuler.findMatchForeground": "#817c9c4d",
    "editorOverviewRuler.incomingContentForeground": "#c4a7e780",
    "editorOverviewRuler.infoForeground": "#9ccfd880",
    "editorOverviewRuler.modifiedForeground": "#ea9a9780",
    "editorOverviewRuler.rangeHighlightForeground": "#817c9c4d",
    "editorOverviewRuler.selectionHighlightForeground": "#817c9c4d",
    "editorOverviewRuler.warningForeground": "#f6c17780",
    "editorOverviewRuler.wordHighlightForeground": "#817c9c26",
    "editorOverviewRuler.wordHighlightStrongForeground": "#817c9c4d",
    "editorPane.background": "#0000",
    "editorRuler.foreground": "#817c9c4d",
    "editorSuggestWidget.background": "#2a273f",
    "editorSuggestWidget.border": "#0000",
    "editorSuggestWidget.focusHighlightForeground": "#ea9a97",
    "editorSuggestWidget.foreground": "#908caa",
    "editorSuggestWidget.highlightForeground": "#ea9a97",
    "editorSuggestWidget.selectedBackground": "#817c9c26",
    "editorSuggestWidget.selectedForeground": "#e0def4",
    "editorSuggestWidget.selectedIconForeground": "#e0def4",
    "editorUnnecessaryCode.border": "#0000",
    "editorUnnecessaryCode.opacity": "#e0def480",
    "editorWarning.border": "#0000",
    "editorWarning.foreground": "#f6c177",
    "editorWhitespace.foreground": "#6e6a86",
    "editorWidget.background": "#2a273f",
    "editorWidget.border": "#393552",
    "editorWidget.foreground": "#908caa",
    "editorWidget.resizeBorder": "#6e6a86",
    "errorForeground": "#eb6f92",
    "extensionBadge.remoteBackground": "#c4a7e7",
    "extensionBadge.remoteForeground": "#232136",
    "extensionButton.prominentBackground": "#ea9a97",
    "extensionButton.prominentForeground": "#232136",
    "extensionButton.prominentHoverBackground": "#ea9a97e6",
    "extensionIcon.preReleaseForeground": "#3e8fb0",
    "extensionIcon.starForeground": "#ea9a97",
    "extensionIcon.verifiedForeground": "#c4a7e7",
    "focusBorder": "#817c9c26",
    "foreground": "#e0def4",
    "gitDecoration.addedResourceForeground": "#9ccfd8",
    "gitDecoration.conflictingResourceForeground": "#eb6f92",
    "gitDecoration.deletedResourceForeground": "#908caa",
    "gitDecoration.ignoredResourceForeground": "#6e6a86",
    "gitDecoration.modifiedResourceForeground": "#ea9a97",
    "gitDecoration.renamedResourceForeground": "#3e8fb0",
    "gitDecoration.stageDeletedResourceForeground": "#eb6f92",
    "gitDecoration.stageModifiedResourceForeground": "#c4a7e7",
    "gitDecoration.submoduleResourceForeground": "#f6c177",
    "gitDecoration.untrackedResourceForeground": "#f6c177",
    "icon.foreground": "#908caa",
    "input.background": "#39355280",
    "input.border": "#817c9c26",
    "input.foreground": "#e0def4",
    "input.placeholderForeground": "#908caa",
    "inputOption.activeBackground": "#ea9a9726",
    "inputOption.activeForeground": "#ea9a97",
    "inputValidation.errorBackground": "#2a273f",
    "inputValidation.errorBorder": "#817c9c4d",
    "inputValidation.errorForeground": "#eb6f92",
    "inputValidation.infoBackground": "#2a273f",
    "inputValidation.infoBorder": "#817c9c4d",
    "inputValidation.infoForeground": "#9ccfd8",
    "inputValidation.warningBackground": "#2a273f",
    "inputValidation.warningBorder": "#817c9c4d",
    "inputValidation.warningForeground": "#9ccfd880",
    "keybindingLabel.background": "#393552",
    "keybindingLabel.border": "#817c9c4d",
    "keybindingLabel.bottomBorder": "#817c9c4d",
    "keybindingLabel.foreground": "#c4a7e7",
    "keybindingTable.headerBackground": "#393552",
    "keybindingTable.rowsBackground": "#2a273f",
    "list.activeSelectionBackground": "#817c9c26",
    "list.activeSelectionForeground": "#e0def4",
    "list.deemphasizedForeground": "#908caa",
    "list.dropBackground": "#2a273f",
    "list.errorForeground": "#eb6f92",
    "list.filterMatchBackground": "#2a273f",
    "list.filterMatchBorder": "#ea9a97",
    "list.focusBackground": "#817c9c4d",
    "list.focusForeground": "#e0def4",
    "list.focusOutline": "#817c9c26",
    "list.highlightForeground": "#ea9a97",
    "list.hoverBackground": "#817c9c14",
    "list.hoverForeground": "#e0def4",
    "list.inactiveFocusBackground": "#817c9c14",
    "list.inactiveSelectionBackground": "#2a273f",
    "list.inactiveSelectionForeground": "#e0def4",
    "list.invalidItemForeground": "#eb6f92",
    "list.warningForeground": "#f6c177",
    "listFilterWidget.background": "#2a273f",
    "listFilterWidget.noMatchesOutline": "#eb6f92",
    "listFilterWidget.outline": "#393552",
    "menu.background": "#2a273f",
    "menu.border": "#817c9c14",
    "menu.foreground": "#e0def4",
    "menu.selectionBackground": "#817c9c26",
    "menu.selectionBorder": "#393552",
    "menu.selectionForeground": "#e0def4",
    "menu.separatorBackground": "#817c9c4d",
    "menubar.selectionBackground": "#817c9c26",
    "menubar.selectionBorder": "#817c9c14",
    "menubar.selectionForeground": "#e0def4",
    "merge.border": "#393552",
    "merge.commonContentBackground": "#817c9c26",
    "merge.commonHeaderBackground": "#817c9c26",
    "merge.currentContentBackground": "#f6c17780",
    "merge.currentHeaderBackground": "#f6c17780",
    "merge.incomingContentBackground": "#9ccfd880",
    "merge.incomingHeaderBackground": "#9ccfd880",
    "minimap.background": "#2a273f",
    "minimap.errorHighlight": "#eb6f9280",
    "minimap.findMatchHighlight": "#817c9c26",
    "minimap.selectionHighlight": "#817c9c26",
    "minimap.warningHighlight": "#f6c17780",
    "minimapGutter.addedBackground": "#9ccfd8",
    "minimapGutter.deletedBackground": "#eb6f92",
    "minimapGutter.modifiedBackground": "#ea9a97",
    "minimapSlider.activeBackground": "#817c9c4d",
    "minimapSlider.background": "#817c9c26",
    "minimapSlider.hoverBackground": "#817c9c26",
    "notebook.cellBorderColor": "#9ccfd880",
    "notebook.cellEditorBackground": "#2a273f",
    "notebook.cellHoverBackground": "#39355280",
    "notebook.focusedCellBackground": "#817c9c14",
    "notebook.focusedCellBorder": "#9ccfd8",
    "notebook.outputContainerBackgroundColor": "#817c9c14",
    "notificationCenter.border": "#817c9c26",
    "notificationCenterHeader.background": "#2a273f",
    "notificationCenterHeader.foreground": "#908caa",
    "notificationLink.foreground": "#c4a7e7",
    "notificationToast.border": "#817c9c26",
    "notifications.background": "#2a273f",
    "notifications.border": "#817c9c26",
    "notifications.foreground": "#e0def4",
    "notificationsErrorIcon.foreground": "#eb6f92",
    "notificationsInfoIcon.foreground": "#9ccfd8",
    "notificationsWarningIcon.foreground": "#f6c177",
    "panel.background": "#2a273f",
    "panel.border": "#0000",
    "panel.dropBorder": "#393552",
    "panelInput.border": "#2a273f",
    "panelSection.dropBackground": "#817c9c26",
    "panelSectionHeader.background": "#2a273f",
    "panelSectionHeader.foreground": "#e0def4",
    "panelTitle.activeBorder": "#817c9c4d",
    "panelTitle.activeForeground": "#e0def4",
    "panelTitle.inactiveForeground": "#908caa",
    "peekView.border": "#393552",
    "peekViewEditor.background": "#2a273f",
    "peekViewEditor.matchHighlightBackground": "#817c9c4d",
    "peekViewResult.background": "#2a273f",
    "peekViewResult.fileForeground": "#908caa",
    "peekViewResult.lineForeground": "#908caa",
    "peekViewResult.matchHighlightBackground": "#817c9c4d",
    "peekViewResult.selectionBackground": "#817c9c26",
    "peekViewResult.selectionForeground": "#e0def4",
    "peekViewTitle.background": "#393552",
    "peekViewTitleDescription.foreground": "#908caa",
    "pickerGroup.border": "#817c9c4d",
    "pickerGroup.foreground": "#c4a7e7",
    "ports.iconRunningProcessForeground": "#ea9a97",
    "problemsErrorIcon.foreground": "#eb6f92",
    "problemsInfoIcon.foreground": "#9ccfd8",
    "problemsWarningIcon.foreground": "#f6c177",
    "progressBar.background": "#ea9a97",
    "quickInput.background": "#2a273f",
    "quickInput.foreground": "#908caa",
    "quickInputList.focusBackground": "#817c9c26",
    "quickInputList.focusForeground": "#e0def4",
    "quickInputList.focusIconForeground": "#e0def4",
    "scrollbar.shadow": "#2a273f4d",
    "scrollbarSlider.activeBackground": "#3e8fb080",
    "scrollbarSlider.background": "#817c9c26",
    "scrollbarSlider.hoverBackground": "#817c9c4d",
    "searchEditor.findMatchBackground": "#817c9c26",
    "selection.background": "#817c9c4d",
    "settings.focusedRowBackground": "#2a273f",
    "settings.focusedRowBorder": "#817c9c26",
    "settings.headerForeground": "#e0def4",
    "settings.modifiedItemIndicator": "#ea9a97",
    "settings.rowHoverBackground": "#2a273f",
    "sideBar.background": "#232136",
    "sideBar.dropBackground": "#2a273f",
    "sideBar.foreground": "#908caa",
    "sideBarSectionHeader.background": "#0000",
    "sideBarSectionHeader.border": "#817c9c26",
    "statusBar.background": "#232136",
    "statusBar.debuggingBackground": "#c4a7e7",
    "statusBar.debuggingForeground": "#232136",
    "statusBar.foreground": "#908caa",
    "statusBar.noFolderBackground": "#232136",
    "statusBar.noFolderForeground": "#908caa",
    "statusBarItem.activeBackground": "#817c9c4d",
    "statusBarItem.errorBackground": "#232136",
    "statusBarItem.errorForeground": "#eb6f92",
    "statusBarItem.hoverBackground": "#817c9c26",
    "statusBarItem.prominentBackground": "#393552",
    "statusBarItem.prominentForeground": "#e0def4",
    "statusBarItem.prominentHoverBackground": "#817c9c26",
    "statusBarItem.remoteBackground": "#232136",
    "statusBarItem.remoteForeground": "#f6c177",
    "symbolIcon.arrayForeground": "#908caa",
    "symbolIcon.classForeground": "#908caa",
    "symbolIcon.colorForeground": "#908caa",
    "symbolIcon.constantForeground": "#908caa",
    "symbolIcon.constructorForeground": "#908caa",
    "symbolIcon.enumeratorForeground": "#908caa",
    "symbolIcon.enumeratorMemberForeground": "#908caa",
    "symbolIcon.eventForeground": "#908caa",
    "symbolIcon.fieldForeground": "#908caa",
    "symbolIcon.fileForeground": "#908caa",
    "symbolIcon.folderForeground": "#908caa",
    "symbolIcon.functionForeground": "#908caa",
    "symbolIcon.interfaceForeground": "#908caa",
    "symbolIcon.keyForeground": "#908caa",
    "symbolIcon.keywordForeground": "#908caa",
    "symbolIcon.methodForeground": "#908caa",
    "symbolIcon.moduleForeground": "#908caa",
    "symbolIcon.namespaceForeground": "#908caa",
    "symbolIcon.nullForeground": "#908caa",
    "symbolIcon.numberForeground": "#908caa",
    "symbolIcon.objectForeground": "#908caa",
    "symbolIcon.operatorForeground": "#908caa",
    "symbolIcon.packageForeground": "#908caa",
    "symbolIcon.propertyForeground": "#908caa",
    "symbolIcon.referenceForeground": "#908caa",
    "symbolIcon.snippetForeground": "#908caa",
    "symbolIcon.stringForeground": "#908caa",
    "symbolIcon.structForeground": "#908caa",
    "symbolIcon.textForeground": "#908caa",
    "symbolIcon.typeParameterForeground": "#908caa",
    "symbolIcon.unitForeground": "#908caa",
    "symbolIcon.variableForeground": "#908caa",
    "tab.activeBackground": "#817c9c14",
    "tab.activeForeground": "#e0def4",
    "tab.activeModifiedBorder": "#9ccfd8",
    "tab.border": "#0000",
    "tab.hoverBackground": "#817c9c26",
    "tab.inactiveBackground": "#0000",
    "tab.inactiveForeground": "#908caa",
    "tab.inactiveModifiedBorder": "#9ccfd880",
    "tab.lastPinnedBorder": "#6e6a86",
    "tab.unfocusedActiveBackground": "#0000",
    "tab.unfocusedHoverBackground": "#0000",
    "tab.unfocusedInactiveBackground": "#0000",
    "tab.unfocusedInactiveModifiedBorder": "#9ccfd880",
    "terminal.ansiBlack": "#393552",
    "terminal.ansiBlue": "#9ccfd8",
    "terminal.ansiBrightBlack": "#908caa",
    "terminal.ansiBrightBlue": "#9ccfd8",
    "terminal.ansiBrightCyan": "#ea9a97",
    "terminal.ansiBrightGreen": "#3e8fb0",
    "terminal.ansiBrightMagenta": "#c4a7e7",
    "terminal.ansiBrightRed": "#eb6f92",
    "terminal.ansiBrightWhite": "#e0def4",
    "terminal.ansiBrightYellow": "#f6c177",
    "terminal.ansiCyan": "#ea9a97",
    "terminal.ansiGreen": "#3e8fb0",
    "terminal.ansiMagenta": "#c4a7e7",
    "terminal.ansiRed": "#eb6f92",
    "terminal.ansiWhite": "#e0def4",
    "terminal.ansiYellow": "#f6c177",
    "terminal.dropBackground": "#817c9c26",
    "terminal.foreground": "#e0def4",
    "terminal.selectionBackground": "#817c9c26",
    "terminal.tab.activeBorder": "#e0def4",
    "terminalCursor.background": "#e0def4",
    "terminalCursor.foreground": "#6e6a86",
    "textBlockQuote.background": "#2a273f",
    "textBlockQuote.border": "#817c9c26",
    "textCodeBlock.background": "#2a273f",
    "textLink.activeForeground": "#c4a7e7e6",
    "textLink.foreground": "#c4a7e7",
    "textPreformat.foreground": "#f6c177",
    "textSeparator.foreground": "#908caa",
    "titleBar.activeBackground": "#232136",
    "titleBar.activeForeground": "#908caa",
    "titleBar.inactiveBackground": "#2a273f",
    "titleBar.inactiveForeground": "#908caa",
    "toolbar.activeBackground": "#817c9c4d",
    "toolbar.hoverBackground": "#817c9c26",
    "tree.indentGuidesStroke": "#908caa",
    "walkThrough.embeddedEditorBackground": "#232136",
    "welcomePage.background": "#232136",
    "welcomePage.buttonBackground": "#2a273f",
    "welcomePage.buttonHoverBackground": "#393552",
    "widget.shadow": "#2a273f4d",
    "window.activeBorder": "#2a273f",
    "window.inactiveBorder": "#2a273f"
  },
  "displayName": "Ros\xE9 Pine Moon",
  "name": "rose-pine-moon",
  "tokenColors": [
    {
      "scope": [
        "comment"
      ],
      "settings": {
        "fontStyle": "italic",
        "foreground": "#6e6a86"
      }
    },
    {
      "scope": [
        "constant"
      ],
      "settings": {
        "foreground": "#3e8fb0"
      }
    },
    {
      "scope": [
        "constant.numeric",
        "constant.language"
      ],
      "settings": {
        "foreground": "#ea9a97"
      }
    },
    {
      "scope": [
        "entity.name"
      ],
      "settings": {
        "foreground": "#ea9a97"
      }
    },
    {
      "scope": [
        "entity.name.section",
        "entity.name.tag",
        "entity.name.namespace",
        "entity.name.type"
      ],
      "settings": {
        "foreground": "#9ccfd8"
      }
    },
    {
      "scope": [
        "entity.other.attribute-name",
        "entity.other.inherited-class"
      ],
      "settings": {
        "fontStyle": "italic",
        "foreground": "#c4a7e7"
      }
    },
    {
      "scope": [
        "invalid"
      ],
      "settings": {
        "foreground": "#eb6f92"
      }
    },
    {
      "scope": [
        "invalid.deprecated"
      ],
      "settings": {
        "foreground": "#908caa"
      }
    },
    {
      "scope": [
        "keyword",
        "variable.language.this"
      ],
      "settings": {
        "foreground": "#3e8fb0"
      }
    },
    {
      "scope": [
        "markup.inserted.diff"
      ],
      "settings": {
        "foreground": "#9ccfd8"
      }
    },
    {
      "scope": [
        "markup.deleted.diff"
      ],
      "settings": {
        "foreground": "#eb6f92"
      }
    },
    {
      "scope": "markup.heading",
      "settings": {
        "fontStyle": "bold"
      }
    },
    {
      "scope": "markup.bold.markdown",
      "settings": {
        "fontStyle": "bold"
      }
    },
    {
      "scope": "markup.italic.markdown",
      "settings": {
        "fontStyle": "italic"
      }
    },
    {
      "scope": [
        "meta.diff.range"
      ],
      "settings": {
        "foreground": "#c4a7e7"
      }
    },
    {
      "scope": [
        "meta.tag",
        "meta.brace"
      ],
      "settings": {
        "foreground": "#e0def4"
      }
    },
    {
      "scope": [
        "meta.import",
        "meta.export"
      ],
      "settings": {
        "foreground": "#3e8fb0"
      }
    },
    {
      "scope": "meta.directive.vue",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#c4a7e7"
      }
    },
    {
      "scope": "meta.property-name.css",
      "settings": {
        "foreground": "#9ccfd8"
      }
    },
    {
      "scope": "meta.property-value.css",
      "settings": {
        "foreground": "#f6c177"
      }
    },
    {
      "scope": "meta.tag.other.html",
      "settings": {
        "foreground": "#908caa"
      }
    },
    {
      "scope": [
        "punctuation"
      ],
      "settings": {
        "foreground": "#908caa"
      }
    },
    {
      "scope": [
        "punctuation.accessor"
      ],
      "settings": {
        "foreground": "#3e8fb0"
      }
    },
    {
      "scope": [
        "punctuation.definition.string"
      ],
      "settings": {
        "foreground": "#f6c177"
      }
    },
    {
      "scope": [
        "punctuation.definition.tag"
      ],
      "settings": {
        "foreground": "#6e6a86"
      }
    },
    {
      "scope": [
        "storage.type",
        "storage.modifier"
      ],
      "settings": {
        "foreground": "#3e8fb0"
      }
    },
    {
      "scope": [
        "string"
      ],
      "settings": {
        "foreground": "#f6c177"
      }
    },
    {
      "scope": [
        "support"
      ],
      "settings": {
        "foreground": "#9ccfd8"
      }
    },
    {
      "scope": [
        "support.constant"
      ],
      "settings": {
        "foreground": "#f6c177"
      }
    },
    {
      "scope": [
        "support.function"
      ],
      "settings": {
        "fontStyle": "italic",
        "foreground": "#eb6f92"
      }
    },
    {
      "scope": [
        "variable"
      ],
      "settings": {
        "fontStyle": "italic",
        "foreground": "#ea9a97"
      }
    },
    {
      "scope": [
        "variable.other",
        "variable.language",
        "variable.function",
        "variable.argument"
      ],
      "settings": {
        "foreground": "#e0def4"
      }
    },
    {
      "scope": [
        "variable.parameter"
      ],
      "settings": {
        "foreground": "#c4a7e7"
      }
    }
  ],
  "type": "dark"
});

export { rosePineMoon as default };
